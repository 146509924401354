import './about-the-urinary-tract.scss';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Definition from '../components/definition/definition';
import ButtonAndTitle from '../components/buttonAndTitle';
import ExternalLink from '../components/externalLink';

import utDiagram from '../images/ut-diagram.svg';
import upperTractDiagram from '../images/upper-tract-diagram.svg';
import upperTractDiagramDesktop from '../images/upper-tract-diagram-desktop.svg';
import tw from 'twin.macro';

const About = () => {
  const { desktop, tablet, mobile } = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "people-02-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      tablet: file(relativePath: { eq: "people-02-tablet.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      mobile: file(relativePath: { eq: "people-02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  // Art-Direction Array
  const backgroundArtDirectionStack = [
    mobile.childImageSharp.fluid,
    {
      ...tablet.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
    {
      ...desktop.childImageSharp.fluid,
      media: `(min-width: 1025px)`,
    },
  ];

  return (
    <Layout>
      <SEO
        title="UTUC | About the Urinary Tract"
        keywords={[
          'UTUC',
          'upper tract urothelial cancer',
          'upper tract urothelial carcinoma',
          'about UTUC',
          'cause of UTUC',
          'causes of UTUC',
          'UTUC causes',
          'why do people get UTUC',
          'UTUC grade',
          'UTUC stage',
        ]}
        description="Learn about the parts of the urinary tract. Urinary tract cancer can happen in the lining inside any of the 4 major parts—the kidneys, ureters, bladder, or urethra"
        page="about"
      />

      <div className="about__container">
        <BackgroundImage
          Tag="section"
          className="about__header page-header"
          fluid={backgroundArtDirectionStack}
          aria-label="About the Urinary Tract"
          critical={true}
          fadeIn={false}
          durationFadeIn={0}
        >
          <div className="about__header_content">
            <div>
              <h2 className="about__header_title page-header__title">
                Urinary tract basics
              </h2>
              <div className="page-header__content">
                <p>
                  The <Definition term="urinary tract" id="urinary_tract" />{' '}
                  works like a drain. It has 4 body parts that work together
                  with nerves and muscles to collect, store, and remove urine
                  from the body.
                </p>
              </div>
            </div>
          </div>
        </BackgroundImage>
        <div className="page-content">
          <section
            className="about__subsection--primary subsection"
            aria-label="How it works"
          >
            <div className="about__subsection_content subsection__content_section">
              <h3 className="subsection__title" style={{ marginTop: '0' }}>
                How it works
              </h3>
              <p>
                The kidneys work to filter your blood. They take any waste and
                make urine. As urine is made, it flows down through the{' '}
                <Definition term="ureters" id="ureter" />
                —the tubes that connect the kidneys to the bladder. Then urine
                is stored in the bladder. When it's time to urinate, the bladder
                empties through the <Definition term="urethra" id="urethra" />.
              </p>
              <h3 className="subsection__title">Cancer in the urinary tract</h3>
              <p>
                Your doctor might say you have urinary tract cancer. Or they
                might call it{' '}
                <Definition
                  term="urothelial carcinoma"
                  id="urothelial_cancer"
                />{' '}
                or{' '}
                <Definition term="urothelial cancer" id="urothelial_cancer" />.
                The word urothelial (uro-THEE-lee-uhl) is used because these
                types of cancer happen in the tissue on the inside of the
                urinary tract. That tissue is called the{' '}
                <Definition term="urothelium" id="urothelium" />{' '}
                (uro-THEE-lee-um).
              </p>
              <p>
                The urothelium tissue lines the inside of all 4 major parts of
                the urinary tract. That means urinary tract cancer can happen in
                the lining inside any of those parts—the kidneys, ureters,
                bladder, or urethra.
              </p>
            </div>
            <div className="about__subsection_diagram subsection__content_section">
              <h3 className="subsection__title">Parts of the urinary tract</h3>
              <img
                src={utDiagram}
                alt="An illustrated and labeled diagram of the Urinary Tract"
                className="about__ut_diagram"
              />
            </div>
          </section>
          <section className="subsection" aria-label="The Upper Tract and UTUC">
            <div className="callout full" tw="flex">
              <UpperTractGrid>
                <UpperTractImg
                  src={upperTractDiagramDesktop}
                  alt="Illustrated diagram of the kidney for desktop screen sizes"
                />
                <UpperTractHeader>
                  <h3 className="callout__title">The upper tract and UTUC</h3>
                  <p>
                    The kidneys and ureters make up the upper part of the
                    urinary tract.
                  </p>
                </UpperTractHeader>
                <UpperTractPointer>
                  <div
                    className="diagram-callout__container about__diagram_callout"
                    tw="mt-0!"
                  >
                    <div className="diagram-callout__arrow_container"></div>
                    <p
                      className="bold color--white"
                      tw="mt-0 text-sm xs:text-base"
                    >
                      Cancer that happens in the lining of the kidneys or
                      ureters is called upper tract urothelial cancer, or UTUC.
                    </p>
                  </div>
                </UpperTractPointer>
                <UpperTractBullets>
                  <p>Your doctor might call this cancer something else, like</p>
                  <ul className="bullet-list">
                    <li className="bullet-list__item">
                      <span>Renal pelvis cancer</span>
                    </li>
                    <li className="bullet-list__item">
                      <span>Ureteral cancer</span>
                    </li>
                    <li className="bullet-list__item">
                      <span>Cancer in the lining of the kidney</span>
                    </li>
                  </ul>
                </UpperTractBullets>
                <UpperTractMoreInfo>
                  <h3 className="callout__title color--white">
                    Did your doctor tell you that you have kidney cancer?
                  </h3>
                  <p>
                    Be sure to ask whether it’s UTUC or another type of cancer
                    that happens in the kidney, called{' '}
                    <Definition
                      term="renal cell cancer"
                      id="renal_cell_cancer"
                    />{' '}
                    or{' '}
                    <Definition
                      term="renal cell carcinoma (RCC)"
                      id="renal_cell_cancer"
                    />
                    .
                  </p>
                  <p>
                    More information about RCC can be found on the&nbsp;
                    <ExternalLink url="https://www.cancer.gov/types/kidney">
                      National Cancer Institute
                    </ExternalLink>
                    &nbsp;website.
                  </p>
                </UpperTractMoreInfo>
              </UpperTractGrid>
            </div>
          </section>
          <section className="subsection" aria-label="Learning more about UTUC">
            <ButtonAndTitle
              title="Did your doctor tell you that you have cancer in your upper urinary tract?"
              linkText="Find out what’s important to know about UTUC"
              link="/understanding-utuc"
              buttonStyle="about__learn_more_btn"
            />
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default About;

const UpperTractGrid = tw.div`
  grid grid-template-columns[minmax(80px, 1fr) 3fr]
  grid-template-rows[repeat(4, auto)] items-start row-gap[2rem]
  md:(
    grid grid-template-columns[200px 3fr] grid-template-rows[auto auto auto]
    items-start row-gap[1rem] column-gap[3rem])`;
const UpperTractImg = tw.img`max-width[200px] grid-column[1] grid-row[2] md:(grid-row[1/4])`;
const UpperTractHeader = tw.div`flex flex-col grid-column[1/3] grid-row[1] md:(grid-column[2] )`;
const UpperTractPointer = tw.div`grid-column[2/3] grid-row[2] ml-10 align-self[center] md:(ml-0 grid-row[2] grid-column[2])`;
const UpperTractBullets = tw.div`grid-column[1/3] grid-row[3] md:(grid-row[3] grid-column[2])`;
const UpperTractMoreInfo = tw.div`grid-column[1/3] grid-row[4] md:(grid-row[4] grid-column[2])`;
